import { DayOfWeek } from "hcl-lib";

export enum TimeSlotType {
  WEEKLY = "WEEKLY",
  ONESHOT = "ONESHOT"
}

export interface ScenarioTimeSlot {
  type: TimeSlotType,
  scenarioId: string,
  allDay: boolean
  priority: number
}

export interface WeeklyScenarioTimeSlot extends ScenarioTimeSlot {
  weekDays?: DayOfWeek[],
  startTime?: string,
  endTime?: string,
}

export interface OneshotScenarioTimeSlot extends ScenarioTimeSlot {
  start?: Date,
  end?: Date
}
