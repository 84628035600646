import { Injectable, OnDestroy } from '@angular/core'
import { Customer, CustomerService, QueryScope, ScopeService } from 'hcl-lib'
import { from, Observable, of, ReplaySubject, Subject, Subscription, zip } from 'rxjs'
import { mergeMap, concatMap, concatAll, toArray, map } from 'rxjs/operators'
import { MediaService } from '../services/media/media.service'


@Injectable({
  providedIn: 'root'
})
export class WhitelabelService implements OnDestroy {

  subscription: Subscription = new Subscription()

  displayName$: Subject<string> = new ReplaySubject<string>(1)
  logoUrl$: Subject<string> = new ReplaySubject<string>(1)
  scrUserGuideUrl$: Subject<string> = new ReplaySubject<string>(1)

  constructor(
    private customerService: CustomerService,
    private mediaService: MediaService,
    private scopeService: ScopeService,
  ) {
    this.subscription.add(
      this.scopeService.getScopeObservable().pipe(
        mergeMap(customerId => from([this.customerService.getAncestorCustomerIds(customerId as string), of([customerId])]).pipe(
          concatAll(),
          concatMap(customerIds => from(customerIds)),
          concatMap(customerId => this.customerService.getCustomer(customerId as string)),
          toArray(),
          mergeMap(customers => of(customers.reverse()))
        )),
        mergeMap(customers => zip(
          of(customers),
          this.retrieveLogoUrl(customers)
        ))
      ).subscribe(([customers, logoUrl]) => {
        this.displayName$.next(this.retrieveDisplayName(customers) as string)
        this.logoUrl$.next(logoUrl as string)
        this.scrUserGuideUrl$.next(this.retrieveScrUserGuideUrl(customers) as string)
      })
    )
  }

  private retrieveDisplayName(customers: Customer[]): string | undefined {
    return customers.find(customer => customer.displayName)?.displayName
  }

  private retrieveLogoUrl(customers: Customer[]): Observable<string | null> {
    for (const customer of customers) {
      if (customer.whiteLabelLogoMediaId) {
        return this.mediaService.getMedia(customer.whiteLabelLogoMediaId, undefined, QueryScope.CUSTOMER_AND_ANCESTORS).pipe(map(media => media.url))
      }
      if (!customer.showAncestorEntities) {
        return of(null)
      }
    }
    return of(null)
  }

  private retrieveScrUserGuideUrl(customers: Customer[]): string | undefined {
    return customers.find(customer => customer.scrUserGuideUrl)?.scrUserGuideUrl
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
