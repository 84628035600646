import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScopeService } from './scope.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeInterceptorService implements HttpInterceptor {

  constructor(private scopeService: ScopeService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.params.has('cid')) {
      request = request.clone({
        setParams: {
          cid: this.scopeService.getScope()
        }
      })
    }
    return next.handle(request)
  }
}
