import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpShowError, InvocationResult, Loader } from 'hcl-lib';
import { HttpUtil } from 'projects/hcl-portal/src/app/common/util/http.util';
import { environment } from 'projects/hcl-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TimeSlotUtil } from '../../../common/util/timeslot.util';
import { ScheduleDto } from '../../interfaces/dto/schedule-dto';
import { OneshotScenarioTimeSlot, TimeSlotType, WeeklyScenarioTimeSlot } from '../../interfaces/scenario-time-slot';
import { Schedule } from '../../interfaces/schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private httpClient: HttpClient,
    private httpUtil: HttpUtil
  ) { }

  @Loader()
  @HttpShowError()
  getSchedulesWithPaging(
    page: Number,
    perPage: Number,
    scenarioId: string | undefined = undefined
  ): Observable<InvocationResult> {
    const url = `${environment.scenarioApiBaseUrl}/schedules`
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    if (scenarioId) {
      queryParams = queryParams.append('scenarioId', scenarioId)
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => res as InvocationResult)
    )
  }

  @Loader()
  @HttpShowError()
  getSchedule(id: string): Observable<Schedule> {
    const url = `${environment.scenarioApiBaseUrl}/schedules/${id}`
    return this.httpClient.get(url).pipe(
      map((res: any) => (res as InvocationResult).data)
    )
  }

  @HttpShowError()
  getScheduleByDevice(deviceId: string): Observable<Schedule> {
    const url = `${environment.scenarioApiBaseUrl}/schedules/device/${deviceId}`
    return this.httpClient.get(url).pipe(
      map((res: any) => (res as InvocationResult).data)
    )
  }

  @Loader(true)
  @HttpShowError(true)
  createSchedule(schedule: Schedule): Observable<Schedule> {
    const url = `${environment.scenarioApiBaseUrl}/schedules`
    const scheduleDto: ScheduleDto = {
      name: schedule.name,
      timeSlots: this.formatTimeSlots(schedule.timeSlots),
      deviceIds: schedule.deviceIds,
      defaultScenarioId: schedule.defaultScenarioId,
      synchronized: schedule.synchronized,
    }
    return this.httpClient.post(url, scheduleDto).pipe(
      map((res: any) => (res as InvocationResult).data)
    )
  }

  @Loader(true)
  @HttpShowError(true)
  updateSchedule(schedule: Schedule): Observable<Schedule> {
    const url = `${environment.scenarioApiBaseUrl}/schedules/${schedule.id}`
    const scheduleDto: ScheduleDto = {
      id: schedule.id,
      customerId: schedule.customerId,
      name: schedule.name,
      timeSlots: this.formatTimeSlots(schedule.timeSlots),
      deviceIds: schedule.deviceIds,
      defaultScenarioId: schedule.defaultScenarioId,
      synchronized: schedule.synchronized,
    }
    return this.httpClient.put(url, scheduleDto).pipe(
      map((res: any) => (res as InvocationResult).data)
    )
  }

  @Loader()
  @HttpShowError()
  deleteSchedule(id: String): Observable<any> {
    const url = `${environment.scenarioApiBaseUrl}/schedules/${id}`
    return this.httpClient.delete(url).pipe(
      catchError(this.httpUtil.handleHttpError)
    )
  }

  formatTimeSlots(timeSlots: any): (WeeklyScenarioTimeSlot|OneshotScenarioTimeSlot)[] {
    return timeSlots.map((timeSlot: any) => {
      if (timeSlot.type == TimeSlotType.ONESHOT) {
        let oneShotTimeSlot = timeSlot as OneshotScenarioTimeSlot
        oneShotTimeSlot.start = TimeSlotUtil.formatToLocalDateTime(oneShotTimeSlot.start as Date)
        oneShotTimeSlot.end = TimeSlotUtil.formatToLocalDateTime(oneShotTimeSlot.end as Date)
      }
      return timeSlot
    })
  }
}
