import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxStripeModule } from 'ngx-stripe';
import { HclConfig } from './config/hcl.config';
import { KeycloakInterceptorService } from './services/keycloak/keycloak.interceptor.service';
import { LoaderService } from './services/loader/loader.service';
import { ScopeInterceptorService } from './services/scope/scope.interceptor.service';
import { ContextMenuModule } from './widgets/contextmenu/contextmenu.module';

export const ngxLoadingModule: ModuleWithProviders<any> = NgxLoadingModule.forRoot({})
export const ngMultiSelectDropDownModule: ModuleWithProviders<any> = NgMultiSelectDropDownModule.forRoot()
export const ngxStripeModule: ModuleWithProviders<any> = NgxStripeModule.forRoot()

@NgModule({
    imports: [
        CommonModule,
        ngxLoadingModule,
        ngxStripeModule,
        A11yModule,
        OverlayModule,
        PortalModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ngMultiSelectDropDownModule,
        DropzoneModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTreeModule,
        MatRippleModule,
        ContextMenuModule.forRoot(),
        TranslateModule,
    ],
    exports: [
        NgxLoadingModule,
        A11yModule,
        OverlayModule,
        PortalModule,
        FormsModule,
        ReactiveFormsModule,
        DropzoneModule,
        NgbModule,
        NgMultiSelectDropDownModule,
        NgxStripeModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTreeModule,
        MatRippleModule,
        ContextMenuModule,
        TranslateModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ScopeInterceptorService,
            multi: true
        },
        LoaderService,
    ]
})
export class HclLibModule {

  static forRoot(hclConfig: HclConfig): ModuleWithProviders<HclLibModule> {
    return {
      ngModule: HclLibModule,
      providers: [
        {
          provide: HclConfig,
          useValue: hclConfig
        }
      ]
    }
  }
}
