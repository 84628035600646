import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {KeycloakService} from './keycloak.service';

@Injectable({
  providedIn: 'root'
})
export class KeycloakInterceptorService implements HttpInterceptor {

  IGNORED_LIST: string[] = [
    "https://api.postermywall.com"
  ]

  constructor(
    private keycloakService: KeycloakService
  ) {
    // do nothing
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ignoreKeycloak: boolean = false

    this.IGNORED_LIST.forEach(item => {
      if (request.url.includes(item)) ignoreKeycloak = true
    })

    if (this.keycloakService.isLoggedIn() && !ignoreKeycloak) {
      return this.keycloakService.getToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              params: request.params.set("realm", this.keycloakService.getRealm()),
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          return next.handle(request);
        }));
    }
    return next.handle(request);
  }
}
