import {
  OverlayModule,
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ContextMenuAttachDirective } from './contextmenu.attach.directive';
import { ContextMenuComponent } from './contextmenu.component';
import { ContextMenuItemDirective } from './contextmenu.item.directive';
import { IContextMenuOptions } from './contextmenu.options';
import { ContextMenuService } from './contextmenu.service';
import { CONTEXT_MENU_OPTIONS } from './contextmenu.tokens';
import { ContextMenuContentComponent } from './contextmenu-content.component';

@NgModule({
  exports: [
    ContextMenuAttachDirective,
    ContextMenuComponent,
    ContextMenuItemDirective,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    ContextMenuAttachDirective,
    ContextMenuComponent,
    ContextMenuContentComponent,
    ContextMenuItemDirective,
  ],
})
export class ContextMenuModule {
  public static forRoot(
    options?: IContextMenuOptions
  ): ModuleWithProviders<ContextMenuModule> {
    return {
      ngModule: ContextMenuModule,
      providers: [
        ContextMenuService,
        {
          provide: CONTEXT_MENU_OPTIONS,
          useValue: options,
        },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
      ],
    };
  }
}
